//Importando dependencias 
@use "../base/variables" as v;
@use "../base/mixins" as m;

.header-bg{
    background-color: v.$naranja;
}
.header-content{
    color: v.$blanco;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-size: 3.5rem;
    }

    @include m.desktop{
        flex-direction: row;
        justify-content: space-between;
    }
}
.navegacion{
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    a{
        color: v.$blanco;
        text-transform: uppercase;
        padding: 3rem 1rem;

        &:hover{
            background-color: darken(v.$naranja, 10%);
        }
    }

    @include m.desktop{
        margin-top: 0;
        flex-direction: row;
        justify-content: space-between;
    }
}

.footer{
    margin-top: 2rem;
    text-align: center;
    background-color: v.$naranja;
    font-size: 2.2rem;
    padding: 1rem 0;
    color: v.$blanco;
}