//Fuente principal del proyecto
$fuentePrincipal : "Poppins", sans-serif;

//Paleta de colores
$blanco : white;
$negro : black;
$naranja : rgb(255, 149, 0);
$azul : rgb(36, 17, 184);
$verde : rgb(17, 81, 17);
$rojo : rgb(162, 10, 10);
$gris : rgb(211, 211, 211);