//Importando dependencias 
@use "../base/variables" as v;
@use "../base/mixins" as m;

.nosotros-bg{
    background-color: v.$gris;
    padding: 1rem 0;
}

.nosotros-grid{

    @include m.desktop{
        @include m.grid(2, 2rem);
    }
    
}

.formulario{
    width: min(70rem, 95%);
    margin: 0 auto;
}

.formulario-content{
    label{
        display: block;
        margin-top: 1.5rem;
        font-weight: bold;
    }
}

.formulario-input{
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 1rem;
    border: 1px solid black;
}