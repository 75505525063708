//Mixins del proyecto
@mixin desktop{
    @media(min-width: 768px){
        @content;
    }
}

@mixin grid($columnas, $gap){
    display: grid;
    grid-template-columns: repeat($columnas, 1fr);
    gap: $gap;
}

@mixin boton($display, $bgcolor, $color){
    text-transform: uppercase;
    padding: 1rem 3rem;
    border-radius: 1rem;
    background-color: $bgcolor;
    color: $color;
    display: $display;
    border: none;
    margin: 1rem auto;
    text-align: center;

    &:hover{
        cursor: pointer;
    }
}