//Importando dependencias 
@use "../base/variables" as v;
@use "../base/mixins" as m;

.fijar{
    overflow: hidden;
}

.layout{
    position: fixed;
    background-color: rgba(v.$negro, 70%);

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: grid;
    place-items: center;

    img{
        display: block;
        max-width: 70%;
        margin: 0 auto;
    }
}

.galeria{
    margin: 4rem auto;

    @include m.desktop{
        @include m.grid(5, 1rem);
    }
}

.galeria-imagen{


    &:hover{
        cursor: pointer;
    }
}