//Importando dependencias 
@use "../base/variables" as v;
@use "../base/mixins" as m;

.boton-azul-line{
    @include m.boton(inline-block, v.$azul, v.$blanco);
}

.boton-naranja-line{
    @include m.boton(inline-block, v.$naranja, v.$blanco);
}

.boton-gris-line{
    @include m.boton(inline-block, v.$gris, v.$blanco);
}

.boton-azul-block{
    @include m.boton(block, v.$azul, v.$blanco);
}

.boton-naranja-block{
    @include m.boton(block, v.$naranja, v.$blanco);
}

.boton-gris-block{
    @include m.boton(block, v.$gris, v.$blanco);
}

.little-boton{
    width: 40rem;
}