//Importando dependencias
@use "variables" as v;

html{
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after{
    box-sizing: inherit;
}
body{
    font-size: 16px;
    font-family: v.$fuentePrincipal;
}
a{
    text-decoration: none;
}
img{
    max-width: 100%;
}
.contenedor{
    width: min(95%, 130rem);
    margin: 0 auto;
}
.no-margin{
    margin: 0;
}
.no-padding{
    padding: 0;
}
.centrar-texto{
    text-align: center;
}
.hidden{
    display: none;
}