//Importando dependencias 
@use "../base/variables" as v;
@use "../base/mixins" as m;

.servicios{
    margin: 3rem auto;
}

.cards{
    margin: 2rem auto;

    @include m.desktop{
        @include m.grid(5, 2rem);
    }
}

.card{
    display: block;
    text-align: center;
    padding: 1rem;
    border-radius: 1rem;
    background-color: v.$gris;
    margin: 2rem auto;

    img{
        height: 25rem;
        width: 25rem;
        max-width: 100%;

    }

    &:hover{
        -webkit-box-shadow: 0px 0px 20px 0px black;
        -moz-box-shadow: 0px 0px 20px 0px black;
        box-shadow: 0px 0px 20px 0px black;
    }
}

.error, .correcto{
    padding: 1rem 0;
    color: v.$blanco;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;

    width: min(70rem, 95%);
    margin: 2.5rem auto;
}
.correcto{
    background-color: v.$verde;
}
.error{
    background-color: v.$rojo;
}

.imagen-servicio{
    text-align: center;
    display: block;
    margin: 0 auto;
    max-width: 60%;
}