//Importando dependencias 
@use "../base/variables" as v;
@use "../base/mixins" as m;

.webp .imagen-info{
    background-image: url("../img/Imagen.webp");
}

.no-webp .imagen-info{
    background-color: url("../img/Imagen.png");
}

.imagen-info{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 50rem;

    position: relative;
    width: 100%;
}

.info{
    position: absolute;
    background-color: rgba(v.$negro, 40%);
    height: 100%;
    width: 100%;

    color: v.$blanco;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo{
    border-radius: 50% 50%;
    width: 20rem;
    height: 20rem;
}

.calidad{
    margin: 3rem auto;
    text-align: center;

    @include m.desktop{
        display: grid;
        grid-template-columns: 30% 70%;
        gap: 2rem;
    }
}

.calidad-content{
    

    @include m.desktop{
        @include m.grid(3, 1rem);
    }
}

.calidad-card{
    margin: 1rem auto;
    height: 25rem;
    width: 25rem;
    background-color: v.$azul;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2{
        margin: 0;
        color: v.$blanco;
        text-align: center;
        font-size: 1.5rem;
        font-weight: normal;
    }

    @include m.desktop{
        margin: 0;
    }
}

.padron-contenedor{
    display: flex;
    align-items: center;
}

.enlace-padron{
    display: inline-block;
    background-color: v.$naranja;
    color: v.$negro;
    padding: 1.5rem;
    border-radius: 1rem;
}